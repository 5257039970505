/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { AreaMaps as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query AreaMapsQuery {
    craft {
      entry(section: "areaMaps") {
        title
        url
        slug
        ... on Craft_areaMaps_areaMaps_Entry {
          uid
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # hero
          heroTitle
          heroLabel
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          heroVideoUrl
          videoAspectRatio
          # hero
          commonHeader
          commonDescription
          # maps
          mapEmbeds {
            title
            caption
            src
          }
        }
      }
    }
  }
`;

const previewQuery = `query AreaMapsQuery {
  craft {
    entry(section: "areaMaps") {
      title
      url
      slug
      ... on Craft_areaMaps_areaMaps_Entry {
        uid
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        # hero
        heroTitle
        heroLabel
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroVideoUrl
        videoAspectRatio
        # hero
        commonHeader
        commonDescription
        # maps
        mapEmbeds {
          title
          caption
          src
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // intro
    commonHeader,
    commonDescription,
    // maps
    mapEmbeds,
  } = craft.entry;

  return {
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    intro: {
      heading: commonHeader,
      copy: commonDescription,
    },
    maps: mapEmbeds,
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
